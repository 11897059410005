import React from 'react';
import UrlInputComponent from "../../components/UrlInputComponent";
import './index.scss'

const AdminPanel: React.FC = () => {
    return (
        <div className="app">
            <p className="regular-22">Company file</p>
            <div className="output-container">
                <UrlInputComponent/>
                <div style={{height: '580px'}}>
                </div>
            </div>
        </div>
    );
};

export default AdminPanel;